<template>
    <a-layout>
        <div style="background-color:#fff;">
            <a-layout-content style="padding:0;">
                <div style=" background: #fff; padding:0px 20px 0; margin:0;">

                    <!--<a-tabs default-active-key="1" @change="radioChange">
                        <a-tab-pane key="1" tab="素材包" />
                    </a-tabs>-->

                    <div v-if="Commission==1">
                        <PackageList />
                    </div>
                    <div v-if="Commission==2">
                        <MyList />
                    </div>
                </div>
            </a-layout-content>
        </div>
    </a-layout>
</template>
<script>
    import PackageList from "@/Views/MaterialModule/Store/PackageList";
    import MyList from "@/Views/MaterialModule/Store/MyList";
    export default {
        name: "List",
        data() {
            return {
                Commission: "1",
                formLayout: 'horizontal',
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 2 }
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 24 }
                    }
                },
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            radioChange: function (item) {
                var self = this;
                self.Commission = item;
            },
            handleFormLayoutChange(e) {
                this.formLayout = e.target.value;
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
            PackageList,
            MyList
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>